.ConversionSettings {
	margin-top: 16px;
	display: flex;
	flex-direction: column;
	gap: 16px;

	&__cards {
		display: flex;
		flex-direction: column;
		gap: 16px;

		&__content {
			display: flex;
			flex-direction: column;
			gap: 16px;

			&__text {
				height: 100px;
				overflow-y: auto;
				word-break: break-all;
				display: flex;
				flex-direction: column;
				gap: 16px;
			}
		}
	}
}

.AddConversionSettings {
	margin-top: 16px;
	display: flex;
	flex-direction: column;
	gap: 16px;
	padding: 16px;
	height: 50vh;
	width: 50vw;
	overflow: hidden;

}

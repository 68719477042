.home {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 16px;
	margin-left: auto;
	margin-right: auto;

	&__cards {
		display: flex;
	}
}

.Delete {
	margin-top: 16px;
	display: flex;
	flex-direction: column;
	gap: 16px;

	&__switch {
		display: flex;
		flex-direction: column;
		gap: 8px;
	}
}

.DeletePopup {
	padding: 16px;
	text-align: left;
	display: flex;
	flex-direction: column;
	gap: 16px;

	&__buttons {
		display: flex;
	}
}

.systemReports {
	width: 100%;
	height: calc(100vh - 56px);
	overflow: auto;
	&__header {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		align-items: center;
		padding: 0 16px;
		width: 100%;
	}

	&__content {
		display: flex;
		flex-direction: column;
		gap: 16px;
		padding: 0 16px;
		margin-bottom: 16px;
	}
}

.addSystemDev {
	padding: 16px;
}

.TenantSettings {
	padding: 16px;
	width: 100vw;
	height: 100%;
	overflow-y: auto;

	&__title {
		width: 100%;
		text-align: center;
		font-size: 32px;
	}
}

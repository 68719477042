.tenants {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	padding: 0 16px;
	height: 100%;
	overflow-x: auto;

	&__title {
		width: 100%;
		display: grid;
		grid-template-columns: 1fr;
		align-items: center;
		margin-bottom: 16px;

		&__text {
			justify-self: center;
			text-align: center;
		}

		&__button {
			justify-self: left;
		}
	}

	&__cards {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 16px;
		height: 100%;

		&__card {
			&__title {
				display: flex;
				align-items: center;
				gap: 16px;

				&__textContainer {
					display: flex;
					align-items: center;
					gap: 16px;
				}
			}
		}

		.list-card__buttons-container {
			gap: 16px;
		}

		.button-container {
			padding: 0 !important;
		}
	}
}

.addTenant {
	display: flex;
	flex-direction: column;
	gap: 16px;
	align-items: center;
	padding: 32px;
	padding-top: 16px;

	&__title {
		margin-bottom: 0;
	}

	&__button {
		width: 100%;
	}
}

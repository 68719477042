.Error {
	margin: 10vh 16px;
	display: flex;
	flex-direction: column;
	align-items: center;
	color: var(--appColour);
	&__image {
		width: 200px;
	}

	&__uhoh {
		margin-bottom: 16px;
	}

	&__something {
		margin: 0;
		margin-bottom: 16px;
	}

	&__text {
		width: 463px;
		display: flex;
		flex-direction: column;
		gap: 16px;

		@media screen and (max-width: 560px) {
			width: 100%;
			padding: 16px;
		}
	}
}

.datasources {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	padding: 0 16px;
	overflow-y: auto;

	&__title {
		width: 100%;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		align-items: center;
		margin-bottom: 16px;

		&__text {
			justify-self: center;
		}

		&__button {
			justify-self: left;
		}
	}

	&__cards {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 16px;
		overflow-y: auto;

		&__title {
			display: flex;
			gap: 16px;
			align-items: center;

			&__text {
				font-weight: 600;
				font-size: 18px;
			}
			&__icon {
				cursor: pointer;
				color: var(--system-critical);
			}
		}
		&__connectionString {
			overflow: hidden;
			height: 40px;
		}

		&__button {
			width: 100%;
		}
	}
}

.addDatasource {
	display: flex;
	flex-direction: column;
	gap: 16px;
	align-items: center;
	width: 600px;
	height: 68vh;
	padding: 16px;
	overflow: hidden;

	&__title {
		margin-bottom: 0;
	}

	&__button {
		width: 100%;
	}
}

.testingPopup {
	padding: 16px;
	text-align: left;
	width: 60vw;
	&__title {
		text-align: center;
		font-size: 24px;
	}

	&__tick {
		color: green;
		height: 24px;
		width: 24px;
	}

	&__cross {
		color: red;
		height: 24px;
		width: 24px;
	}

	&__result {
		display: flex;
		align-items: center;
		gap: 16px;
		&__text {
			font-size: 16px;
		}
	}
}

.LandingPage {
	margin: 10vh 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	color: var(--appColour);
	&__image {
		width: 200px;
	}
}

body {
	margin: 0;
	color: var(--fontColour)
}

.main {
	display: flex;
}

.lightRepGen {
	background-image: url("./images/Background.png");
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	height: 100vh;
}

.darkRepGen {
	background-image: url("./images/Background.png");
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.8);
	height: 100vh;
}

.userPanel {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	border: 0;
	border-radius: 2px;
	list-style-type: none;
	background-color: var(--neutralBackground);
	z-index: 10000;
	box-shadow: rgb(0 0 0 / 13%) 0px 3.2px 7.2px 0px, rgb(0 0 0 / 11%) 0px 0.6px 1.8px 0px;
	width: fit-content;
	position: absolute;
	top: 45px;
	right: 10px;
	align-items: center;

	&__item {
		display: flex;
		justify-content: space-between;
		background-color: var(--neutralBackground);
		font-size: 16px;
		padding: 15px 20px 15px 10px;
		border: 0;
		text-align: left;
		color: var(----fontColour);

		&:hover,
		&:focus,
		&--selected {
			cursor: pointer;
			background-color: var(--itemHover);
		}
		justify-content: flex-start;
		align-items: center;
		width: 100%;
		font-size: 14px;

		&__icon {
			margin-right: 10px;
			align-items: center;

			color: var(--appColour);
		}
	}
}

.audits{
    width: 100%;
    padding: 16px;
    overflow-y: auto;

    .list-card{
        min-height: unset !important;
    }

    &__filterButton{
        display: flex;
        align-items: center;
        gap: 4px;
        font-size: 24px;
        cursor: pointer;

        & svg{
            font-size: 24px;
        }
    }

    &__filters{
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-bottom: 16px;
    }

    &__list{
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

}
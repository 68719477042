.email {
	margin-top: 16px;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 16px;
	height: 100%;
	overflow-y: auto;

	&__checkboxes {
		display: flex;
		gap: 16px;
		flex-wrap: wrap;
	}

	&__buttons {
		width: 100%;
		display: flex;
		justify-content: space-between;
	}

	&__delete {
		padding: 16px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 4px;
	}
}

.moveReports {
	height: 100%;
	width: 100%;
	padding: 16px;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	gap: 16px;
	overflow-x: hidden;

    .textarea{
        field-sizing: content;
    }
}

.CookieBanner{
    position: absolute;
    bottom: 10px;
    left: 15%;
    align-items: center;
    background-color: var(--neutralBackground);
    color: var(--fontColor);
    border-radius: 4px;
    padding: 16px;
    width: fit-content;
    white-space: nowrap;

    &__buttons{
        display: flex;
        align-items: center;
        gap: 16px;
        justify-content: flex-end;
        margin-top: 16px;
    }
}